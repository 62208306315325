<template>
  <div class="mcia-outer-conatiner">
    <div class="mcia-inner-container">
      <mds-layout-grid class="product-details-section">
        <mds-row align-horizontal="center">
          <mds-col
            class="product-details-section-row-description"
            :cols="12"
            :cols-at-s="6"
            :cols-at-m="7"
          >
            <div v-html="description" class="product-details-section-row-description"/>
          </mds-col>
          <mds-col
            align-vertical="center"
            :cols="12"
            :cols-at-s="6"
            :cols-at-m="5"
          >
            <img class="product-details-section-row-img" :src="imagePath()" />
          </mds-col>
        </mds-row>
        <mds-row class="product-details-section-row">
          <mds-col :cols="12">
            <mds-button
              class="product-details-section-row-action-btn"
              variation="primary"
              size="large"
              @click="signIn"
            >
              Sign up for Demo and Trial
            </mds-button>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsButton } from '@mds/button';
export default {
  name: 'ProductDetails',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton,
  },
  methods: {
    imagePath() {
      return require('../../assets/' + this.imageFileName);
    },
    signIn() {
      this.$emit('redirectToGetStartedForm');
    },
  },
  props: {
    description: {
      type: String,
      default: '',
      required: true,
    },
    imageFileName: {
      type: String,
      default: '',
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.product-details-section {
  height: auto;
  min-height: 400px;
  margin: 0 auto;
  &-row {
    &-description {
      @include mds-body-text-l();
      color: $mds-text-color-primary;
      margin-top: $mds-space-2-x;
      margin-bottom: $mds-space-2-x;
    }
    &-img {
      max-width: 100%;
      max-height: 100%;
      padding: $mds-space-3-x;
      display: block;
    }
    &-action-btn {
      margin-top: $mds-space-2-x;
      margin-bottom: $mds-space-2-x;
    }
  }
}
.mcia-outer-conatiner {
  .mcia-inner-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: $mds-space-4-x;
    padding-bottom: $mds-space-4-x;
  }
}
</style>
